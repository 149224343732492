
import { Action, Getter } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";
import { FormBase, FormInput, FormError, FormSubmit } from "@/components/forms";
import { PublicOffice } from "@/store/modules/public-office.store";

@Component({
    components: {
        FormBase,
        FormInput,
        FormError,
        FormSubmit,
        Spinner: () => import("@/components/general/spinner.vue"),
    },
})
export default class PageRegister extends Vue {
    @Action("auth/register") register!: Register;
    @Action("auth/login") login!: Login;
    @Action("invite/read") readInvite!: (payload: { invite_code: string }) => Promise<Invite>;
    @Action("public-office/read") readPublicOffice!: (payload: { office_id?: number; setStyling?: boolean }) => Promise<PublicOffice>;
    @Getter("public-office/viewing") currentOffice!: PublicOffice;

    payload: RegisterPayload = {
        email: "",
        password: "",
        first_name: "",
        last_name: "",
        locale: "nl_BE",
        invite_code: "",
    };

    passwordVisible: boolean = false;

    step: number = 1;
    loaded: boolean = false;
    roles: number[] = [];

    created(): void {
        if (this.$route.params.token) {
            this.payload.invite_code = this.$route.params.token as string;
            this.readInvite({ invite_code: this.payload.invite_code })
                .then((invite: Invite) => {
                    this.payload.email = invite.prefilled_data.email;
                    this.payload.first_name = invite.prefilled_data.first_name;
                    this.payload.last_name = invite.prefilled_data.last_name;
                    this.roles = invite.roles;

                    if (this.isSeller) {
                        this.readPublicOffice({ office_id: invite.seller_activity_office_id ?? 1, setStyling: true }).then((o) => {
                            Vue.$cookies.set("hubr-office", o, 0);
                        });
                    }

                    this.loaded = true;
                })
                .catch(() => {
                    this.$router.push({ name: "login" });
                });
        }
    }

    private submit(form: FormClass) {
        this.register(this.payload)
            .then(() => {
                form.success = false;
                form.loading = true;
                this.login({ email: this.payload.email, password: this.payload.password })
                    .then(() => {
                        this.$router.push({ name: "home" });
                    })
                    .catch((errorResponse: ErrorResponse) => (this.$errorResponse = errorResponse))
                    .finally(() => form.reset());
            })
            .catch((errorResponse: ErrorResponse) => (this.$errorResponse = errorResponse))
            .finally(() => form.reset());
    }

    get isSeller() {
        return this.roles.includes(3);
    }
}
